<template>
  <div class="card border-0 rounded-0 h-100">
    <div class="card-body d-flex flex-column" style="height: calc(100% - 4rem); padding: 1.5rem 2rem 0;">
      <h5 class="card-title mb-3">
        <span>Project:<span class="fw-bold ms-2">{{project_data.Name}}</span></span>
        <div class="form-chips p-0 ms-2" style="min-height: 1.5rem;">
          <span v-if="is_xcloud" class="chip no-icon text-bg-primary m-0">X-Cloud</span>
          <span v-if="!is_xcloud" class="chip no-icon text-bg-secondary m-0">None X-Cloud</span>
        </div>
        <div v-if="project_data?.Versions?.filter(v => v.moduleName)?.length > 0" class="d-flex ms-5">
          Module:
          <div>
            <a v-if="project_data?.Versions?.length <= 1" class="fw-bold ms-2" href="#">{{module_data.moduleName || 'None'}}</a>
            <a v-if="project_data?.Versions?.length > 1" class="fw-bold ms-2" href="#" data-bs-toggle="dropdown">{{module_data.moduleName || 'None'}}</a>
            <ul v-if="project_data?.Versions?.length > 1" class="dropdown-menu shadow">
              <li v-for="(module, index) in project_data.Versions" :key="index">
                <a class="dropdown-item" href="#" @click="onChangeModule(module.moduleId)">{{module.moduleName || 'None'}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!version" class="form-chips p-0 ms-2" style="min-height: 1.5rem;">
          <span class="chip no-icon m-0">No version(s)</span>
        </div>
        <div v-if="version" class="form-chips p-0 ms-2" style="min-height: 1.5rem;">
          <span v-if="module_data.versions.length <= 1" class="chip no-icon m-0">{{versionDisplay()}}</span>
          <span v-if="module_data.versions.length > 1" class="chip no-icon m-0 dropdown-toggle" data-bs-toggle="dropdown">{{versionDisplay()}}</span>
          <ul v-if="module_data.versions.length > 1" class="dropdown-menu shadow">
            <li v-for="(version, index) in module_data.versions" :key="index">
              <a class="dropdown-item" href="#" @click="onChangeVersion(version.version)">{{version.version+(index==0?' (latest)':'')}}</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click="onChangeVersion('latest')">All versions</a>
            </li>
          </ul>
        </div>
        <div class="d-flex gap-2 ms-auto">
          <input type="file" id="fileImport" class="d-none" accept=".resx,.json,.csv,.xml" @change="onImport" />
          <button type="button" class="btn btn-success" @click="onImportSelect()">
            <span v-if="page_importing" class="spinner-grow spinner-grow-sm"></span>
            <span v-if="page_importing" class="mx-2">Importing ...</span>
            <font-awesome-icon v-if="!page_importing" icon="fa-solid fa-file-import" class="me-2" />
            <span v-if="!page_importing" class="mx-2">Import</span>
          </button>
          <div>
            <button type="button" class="btn btn-primary" data-bs-toggle="dropdown">
              <span v-if="page_exporting" class="spinner-grow spinner-grow-sm"></span>
              <span v-if="page_exporting" class="mx-2">Exporting ...</span>
              <font-awesome-icon v-if="!page_exporting" icon="fa-solid fa-file-export" class="me-2" />
              <span v-if="!page_exporting" class="mx-2">Export</span>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="onExport(1)">File .resx</a></li>
              <li><a class="dropdown-item" href="#" @click="onExport(2)">File .json</a></li>
              <li><a class="dropdown-item" href="#" @click="onExport(3)">File .csv</a></li>
              <li><a class="dropdown-item" href="#" @click="onExport(4)">File .xml</a></li>
            </ul>
          </div>
        </div>
      </h5>

      <div class="d-flex align-items-center mb-3">
        <div class="d-flex align-items-center me-auto">
          Show
          <select class="form-select form-select-sm mx-2" :value="page_size" @change="onChangePageSize($event)">
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries
        </div>
        <div class="d-flex align-items-center">
          <span class="text-nowrap">Resource type</span>
          <select class="form-select form-select-sm mx-2" :value="resource_type" @change="onChangeResourceType($event)">
            <option value="0">All</option>
            <option value="1">Translated</option>
            <option value="2">Untranslated</option>
          </select>
        </div>
        <div class="search-wrapper" style="max-width: 20rem;">
          <input type="text" class="form-control form-control-sm input-search" placeholder="Search" :value="search_value" @keyup="onChangeSearch($event)" />
          <button type="button" class="btn btn-sm button-search">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          </button>
        </div>
      </div>

      <ul v-if="project_data.Languages?.length > 0" class="nav nav-tabs">
        <li v-for="(language, index) in project_data.Languages.filter(l => l.IsDefault)" :key="index" class="nav-item">
          <a class="nav-link fw-bold active" href="#" data-bs-toggle="tab" data-bs-target="#resource-content" @click="onChangeLanguage(language.Code)" v-html="language.Name+getResourceCount(language.Code)"></a>
        </li>
        <li v-for="(language, index) in project_data.Languages.filter(l => !l.IsDefault)" :key="index" class="nav-item">
          <a class="nav-link fw-bold" href="#" data-bs-toggle="tab" data-bs-target="#resource-content" @click="onChangeLanguage(language.Code)" v-html="language.Name+getResourceCount(language.Code)"></a>
        </li>

      </ul>
      <div class="tab-content overflow-hidden pt-3">
        <div class="tab-pane h-100 overflow-hidden active" id="resource-content">
          <div id="resourceWrapper" class="table-responsive h-100 pb-2{{page_loading?' overflow-hidden':''}}" @scroll="onScroll">
            <table class="table table-centered table-nowrap table-resource mb-0">
              <thead class="table-light">
                <tr class="shadow-sm">
                  <th class="px-3"><span class="d-block text-center" style="width: 4rem;">Order</span></th>
                  <th style="width: 30%;">Key</th>
                  <th style="width: 35%;">Default Value</th>
                  <th class="pe-3" style="width: 35%;">Current Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="page_loading && !loadMore">
                  <td><span class="skeleton">col1</span></td>
                  <td><span class="skeleton">col2</span></td>
                  <td><span class="skeleton">col3</span></td>
                  <td><span class="skeleton">col4</span></td>
                </tr>
                <tr v-for="(resource, index) in resource_datas" :key="index" :class="resource.Value!==resource.OriginValue?'bg-info bg-opacity-10':''">
                  <td class="px-3 text-center">{{index+1}}</td>
                  <td>{{resource.Key}}</td>
                  <td>{{resource.DefaultValue}}</td>
                  <td class="pe-3">
                    <textarea class="form-control" v-model="resource.Value" @change="onEditResource()"></textarea>
                  </td>
                </tr>
                <tr v-if="resource_total > resource_datas.length">
                  <td><span class="skeleton">col1</span></td>
                  <td><span class="skeleton">col2</span></td>
                  <td><span class="skeleton">col3</span></td>
                  <td><span class="skeleton">col4</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-between" style="height: 4rem; padding: 0 2rem;">
      <span>Showing {{resource_datas.length}} of {{resource_total}} records</span>
      <button type="button" class="btn btn-primary" :disabled="resource_editeds.length <= 0 || page_saving" @click="onSave()">
        <span v-if="page_saving" class="spinner-grow spinner-grow-sm"></span>
        <span v-if="page_saving" class="mx-2">Saving ...</span>
        <font-awesome-icon v-if="!page_saving" icon="fa-solid fa-save" class="me-2" />
        <span v-if="!page_saving" class="mx-2">Save</span>
      </button>
    </div>
  </div>
</template>

<script>

  import { mapActions, mapGetters, mapMutations } from "vuex";

  export default {
    name: 'ProjectDetails',
    components: { },
    data() {
      return {
        searchInterval: null,
        loadMore: false
      };
    },
    computed: {
      ...mapGetters([
        "page_exporting", "page_importing", "page_loading", "page_saving",
        "project_data", "module_data", "is_xcloud", "language", "version",
        "page_index", "page_size", "resource_type", "search_value",
        "resource_counts", "resource_datas", "resource_editeds", "resource_total"
      ])
    },
    mounted() {
      this.loadProject()
        .then(res => {
          if(res && res.success) {
            this.loadResourceCount();
            this.loadResources();
          }
        });
    },
    methods: {
      ...mapActions([ "alertMessage", "projectGet", "resourceCount", "resourceExport", "resourceImport", "resourceRead", "resourceSave"]),
      ...mapMutations(["setLanguage", "setModule", "setVersion", "setPageIndex", "setPageSize", "setResourceType", "setSearchValue", "checkEditedResources"]),
      getResourceCount(language) {
        const count = this.resource_counts.find(c => c.LanguageCode === language);
        if(count) {
          const totalUnTranslated = (count.Total || 0) - (count.TotalTranslated || 0);
          if(totalUnTranslated > 0)
            return `<span class="badge bg-danger rounded-1 ms-2">${totalUnTranslated}</span>`;
        }
        return '';
      },
      loadProject() {
        const id = this.$route.params.id || 0;
        return this.projectGet({ id: id });
      },
      loadResources() {
        if(!this.loadMore) {
          const resourceWrapper = document.getElementById('resourceWrapper');
          resourceWrapper.scrollTop = 0;
        }

        this.resourceRead()
          .then(() => { this.loadMore = false; });
      },
      loadResourceCount() {
        this.resourceCount();
      },
      onChangeLanguage(language) {
        if(language !== this.language) {
          this.setPageIndex(0);
          this.setLanguage(language);
          this.loadResources();
        }
      },
      onChangeModule(moduleId) {
        if(moduleId !== this.module_data.moduleId) {
          this.setPageIndex(0);
          this.setResourceType(0);
          this.setSearchValue('');
          this.setModule(moduleId);
          this.loadResourceCount();
          this.loadResources();
        }
      },
      onChangePageSize(evt) {
        this.setPageIndex(0);
        this.setPageSize(+evt.target.value);
        this.loadResources();
      },
      onChangeResourceType(evt) {
        this.setPageIndex(0);
        this.setResourceType(+evt.target.value);
        this.loadResourceCount();
        this.loadResources();
      },
      onChangeSearch(evt) {
        clearInterval(this.searchInterval);

        if(evt.target.value === this.search_value)
          return;

        this.searchInterval = setInterval((searchValue) => {
          clearInterval(this.searchInterval);
          this.setPageIndex(0);
          this.setSearchValue(searchValue);
          this.loadResourceCount();
          this.loadResources();
        }, 500, evt.target.value);
      },
      onChangeVersion(version) {
        if(version !== this.version) {
          this.setPageIndex(0);
          this.setResourceType(0);
          this.setSearchValue('');
          this.setVersion(version);
          this.loadResourceCount();
          this.loadResources();
        }
      },
      onEditResource() {
        this.checkEditedResources();
      },
      onExport(type) {
        this.resourceExport({ type: type });
      },
      onImport(evt) {
        if(evt.target.files.length <= 0)
          return;

        const file = evt.target.files[0];
        if (['application/json', 'application/xml', 'text/csv', 'text/xml'].indexOf(file.type) === -1) {
          evt.target.value = '';
          this.alertMessage({ message: `${file.name} is invalid. Only accept file extension .json,.resx,.csv` });
          return;
        }
        let breeceResources = null
        const $this = this;
        const reader = new FileReader();
        reader.onerror = function(ee) { console.log(ee); };
        reader.onload = function (se) {
          let contentData = null;
          if(['application/json'].indexOf(file.type) > -1 && ['ass_slot_popup_displayname'].indexOf(se.target.result)){
            let tempData = {'en-US': [], 'da-DK': [], 'de-DE': [], 'it-IT': [], 'sv-SE': [], 'no-NO': []}
            breeceResources = JSON.parse(se.target.result)
            for(const [key, value] of Object.entries(breeceResources)){
              tempData['en-US'].push({
                Key: key,
                Value: value['en']
              })
              tempData['da-DK'].push({
                Key: key,
                Value: value['da']
              })
              tempData['de-DE'].push({
                Key: key,
                Value: value['de']
              })
              tempData['it-IT'].push({
                Key: key,
                Value: value['it']
              })
              tempData['sv-SE'].push({
                Key: key,
                Value: value['se']
              })
              tempData['no-NO'].push({
                Key: key,
                Value: value['no']
              })
              
            }
            contentData = JSON.stringify({...tempData})
          } else {
            contentData = se.target.result
          }
          const data = { type: file.type, contents: contentData };
          $this.resourceImport(data)
            .then(res => {
              evt.target.value = '';
              if(res && res.success) {
                $this.setPageIndex(0);
                $this.setResourceType(0);
                $this.setSearchValue('');
                $this.loadResourceCount();
                $this.loadResources();
              }
            });
        };
        reader.readAsText(file, 'utf8');
      },
      onImportSelect() {
        const fileImport = document.getElementById('fileImport');
        fileImport.click();
      },
      onSave() {
        this.resourceSave()
          .then(res => {
            if(res && res.success)
              this.loadResourceCount();
          });
      },
      onScroll(evt) {
        evt.target.querySelector('thead').style.transform = `translateY(${evt.target.scrollTop}px)`;

        if(this.page_loading || this.resource_datas.length === this.resource_total)
          return;

        if(evt.target.scrollTop + evt.target.clientHeight >= evt.target.scrollHeight) {
          this.loadMore = true;
          this.setPageIndex(this.page_index + 1);
          this.loadResources();
        }
      },
      versionDisplay() {
        if(this.version === 'latest')
          return 'All versions';

        const versionIndex = this.module_data.versions.findIndex(v => v.version === this.version);
        return versionIndex === 0 ? this.version + ' (latest)' : this.version;
      }
    }
  }

</script>

<style>

  table.table-resource textarea.form-control {
    height: calc(1.5em + .75rem + 2px);
    min-height: unset;
  }

  .search-wrapper {
    position: relative;
    width: 100%;
  }

  .search-wrapper .button-search {
    position: absolute;
    top: 1px;
    right: 1px;
    color: #9fa9ba;
    border: 0;
  }

  .search-wrapper .input-search {
    padding-right: 2rem;
  }

</style>