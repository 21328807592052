<template>
  <div class="main-content">
    <router-view/>
  </div>
  <div class="toast-container position-fixed bottom-0 p-3">
    <AlertMessage />
  </div>
</template>

<script>

  import AlertMessage from "./AlertMessage.vue";

  export default {
    name: 'MainContent',
    components: { AlertMessage }
  }

</script>

<style>

  @import url(../assets/styles/main.css);

</style>
